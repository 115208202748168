<template>
  <div class="operationsmanage-container">
    <vab-query-form>
      <vab-query-form-left-panel :span="9">
        <el-button icon="el-icon-plus" size="small" style="margin: 10px 0 10px 10px" type="primary" @click="handleEdit">
          新增标签
        </el-button>
        <el-button icon="el-icon-delete" size="small" style="margin: 10px 0 10px 10px" type="danger"
                   @click="handleDelete">
          批量删除
        </el-button>
      </vab-query-form-left-panel>
    </vab-query-form>
    <el-table :data="list" @selection-change="setSelectRows">
      <el-table-column
          v-loading="listLoading"
          align="center"
          show-overflow-tooltip
          type="selection"
      />
      <el-table-column
          align="center"
          label="ID"
          prop="id"
          show-overflow-tooltip
          width="100"
      />
      <el-table-column
          align="center"
          label="标签名"
          min-width="120"
          prop="label_name"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="会员人数"
          min-width="120"
          prop="members"
          show-overflow-tooltip
      />
      <el-table-column
          align="center"
          label="打标条件"
          min-width="120"
          prop="conditions"
          show-overflow-tooltip
      />
      <el-table-column align="center" fixed="right" label="操作" width="120">
        <template #default="{ row }">
          <el-button type="text" size="small" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" size="small" @click="handleDelete(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.curr_page"
        :layout="layout"
        :page-size="queryForm.page_size"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <edit ref="edit" @fetch-data="fetchData"/>
  </div>
</template>

<script>
import Edit from './components/UserlabelEdit'

export default {
  name: 'userlabel',
  components: {Edit},
  data() {
    return {
      list: [
        {id: 1, label_name: '标签1', members: 20, conditions: '消费次数大于10次'},
        {id: 2, label_name: '标签2', members: 30, conditions: '消费金额大于1000元'},
      ],
      listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      total: 0,
      selectRows: '',
      queryForm: {
        curr_page: 1,
        page_size: 10,
      },
    }
  },
  created() {
    // this.fetchData()
  },
  methods: {
    setSelectRows(val) {
      this.selectRows = val
    },
    //新增/编辑
    handleEdit(row) {
      console.log(row)
      if (row.id) {
        this.$refs['edit'].showEdit(row)
      } else {
        this.$refs['edit'].showEdit()
      }
    },
    //删除
    handleDelete(row) {
      console.log(row)
      /*if (row.id) {
        this.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { code, code_user_msg } = await delMeberList({
            employee_id: sessionStorage.getItem('uid'),
            token_key: sessionStorage.getItem('token'),
            id: row.id,
          })
          if (code == 200) {
            this.$baseMessage(code_user_msg, 'success')
            await this.fetchData()
          } else {
            this.$baseMessage(code_user_msg, 'error')
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const ids = this.selectRows.map((item) => item.id).join()
          this.$baseConfirm('你确定要删除选中项吗', null, async () => {
            const { code, code_user_msg } = await delBatchMeber({
              ids,
              employee_id: this.queryForm.employee_id,
              token_key: this.queryForm.token_key,
            })
            if (code == 200) {
              this.$baseMessage(code_user_msg, 'success')
              await this.fetchData()
            } else {
              this.$baseMessage(code_user_msg, 'error')
            }
          })
        } else {
          this.$baseMessage('未选中任何行', 'error')
          return false
        }
      }*/
    },
    handleSizeChange(val) {
      this.queryForm.page_size = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.curr_page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.curr_page = 1
      this.fetchData()
    },
    async fetchData() {
      // this.listLoading = true
      /*const { code, result } = await getMeberList(this.queryForm)
      console.log(result)
      if (code == 200) {
        const { data, total } = result
        const len = data.length
        for (let i = 0; i < len; i++) {
          if (data[i]['entry_date'] == null) {
            data[i]['entry_date'] = parseTime(
                data[i]['create_at'],
                '{yyyy}-{mm}-{dd}'
            )
          }
        }
        this.list = data
        this.total = total
      }*/
      // this.listLoading = false
    },
  },
}
</script>

