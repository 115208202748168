<template>
  <el-dialog
      :title="title"
      :visible.sync="dialogFormVisible"
      width="450px"
      @close="close"
  >
    <el-form ref="form" label-width="100px" :model="form" :rules="rules">
      <el-form-item label="标签名称:" prop="label_name">
        <el-input v-model="form.label_name" size="small" placeholder="请输入标签名称"/>
      </el-form-item>
      <el-form-item label="类型" prop="label_type">
        <el-checkbox-group v-model="form.label_type" size="small">
          <el-checkbox label="0" @change="getCheckValue1">消费次数</el-checkbox>
          <el-checkbox label="1" @change="getCheckValue2">消费金额</el-checkbox>
          <!--          <el-checkbox label="2" @change="getCheckValue3">账户余额</el-checkbox>-->
        </el-checkbox-group>
        <!--        <el-select
                    v-model="form.label_type"
                    placeholder="请选择类型"
                    style="width: 100%"
                    size="small"
                >
                  <el-option label="消费次数" :value="0"/>
                  <el-option label="消费金额" :value="1"/>
                </el-select>-->
      </el-form-item>
      <el-form-item v-show="check_state1" label="消费次数:" prop="number">
        <el-input v-model="form.number" type="number" size="small" placeholder="请输入消费次数"/>
      </el-form-item>
      <el-form-item v-show="check_state2" label="消费金额:" prop="money">
        <el-input v-model="form.money" type="number" size="small" placeholder="请输入消费金额"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button size="small" @click="close">取 消</el-button>
      <el-button size="small" type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "UserlabelEdit.vue",
  data() {
    return {
      check_state1: false,
      check_state2: false,
      title: '新增标签',
      ruleArr: [],
      form: {
        id: '',
        label_name: '',
        label_type: [],
        number: '',
        money: '',
      },
      rules: {
        label_name: [
          {required: true, trigger: 'blur', message: '标签名不能为空!'},
        ],
        conditions: [
          {required: true, trigger: 'blur', message: '打标条件不能为空!'},
        ],
      },
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    getCheckValue1(event) {
      console.log(event)
      this.check_state1 = event
    },
    getCheckValue2(event) {
      console.log(event)
      this.check_state2 = event
    },
    showEdit(row) {
      if (!row) {
        this.title = '新增标签'
      } else {
        console.log(row)
        this.title = '编辑标签'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      /*this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const { code_user_msg } = this.form.id
              ? await updateDepartment(this.form)
              : await addDepartment(this.form)
          this.$baseMessage(code_user_msg, 'success')
          this.$emit('fetch-data')
          this.close()
        } else {
          return false
        }
      })*/
    },
  },
}
</script>

<style scoped>

</style>